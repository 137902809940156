import React from 'react'


import { MDBContainer, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact'
import { faSkype, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  constructor(props) {
    super(props);
    this.state = {
      whatsappLink: '',
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
      const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
      this.setState({ whatsappLink: link });
    }
  }


  render() {
    const { whatsappLink } = this.state;
    return (
      <section className="banner mb-0">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className='bluecolor'>Cryptocurrency</span> Exchange
                  Script</h1>
                <p className="sub-heading">Select Your Cryptocurrency Exchange Script Type</p>
                <div className="tot">
                  <label className="labl">
                    <input onClick={this.toggle} type="radio" name="radioname" value="one_value" />
                    <div  className="binance colored">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/binance-clone-script.webp"
                        alt="binance-clone-script-image-1"
                        width={90}
                        className='d-none d-md-block' />
                     <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/binance-clone-script.png"
                        alt="coinbase-clone-script-image-1"
                        width={135}
                        className='d-block d-md-none' />   
                    </div>
                    <MDBContainer className="site-modal">
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle} centered>
                      <MDBModalHeader toggle={this.toggle}>
                        Start Your Free Trial Now
                      </MDBModalHeader>

                      <MDBModalFooter>
                        <a className="whtsmodal mobile-hide" rel="noreferrer" target="_blank" href={whatsappLink} >
                          <span><FontAwesomeIcon icon={faWhatsapp} /></span>
                          <span>whatsapp</span>
                        </a>
                        <a className="whtsmodal mobile-on" rel="noreferrer" target="_blank" href={whatsappLink} >
                          <span><FontAwesomeIcon icon={faWhatsapp} /></span>
                          <span>whatsapp</span>
                        </a>
                        <a className="skypemodal" rel="noreferrer" target="_blank" href="skype:live:hello_20214?chat" >
                          <span><FontAwesomeIcon icon={faSkype} /></span>
                          <span>skype</span>
                        </a>
                      </MDBModalFooter>
                    </MDBModal>
                  </MDBContainer>
                  
                  </label>
                  <label className="labl">
                    <input onClick={this.toggle} type="radio" name="radioname" value="another" />
                    <div  className="binance colored coinb">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/okx.png"
                        alt="okx-clone-script-image-1"
                        width={90}
                        className='d-none d-md-block' />
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/okx-mob.png"
                        alt="okx-clone-script-image-1"
                        width={95}
                        className='d-block d-md-none' />
                    </div>
                  </label>
                  <label className="labl">
                    <input onClick={this.toggle} type="radio" name="radioname" value="another" />
                    <div className="binance colored locbit">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/bybit.png"
                        alt="bybit"
                        width={90}
                        className='d-none d-md-block' />
                      <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/bybit-mob.png"
                        alt="bybit-clone-script"
                        width={95}
                        className='d-block d-md-none' />
                    </div>
                  </label>
                </div>
                <div className="cta-banner">
                  <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-banner">
                <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home/crypto-exchange-development-script.png"
                  alt="Cryptocurrency Exchange Script Banner"
                  width={789}
                  className='d-none d-lg-block'
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner