import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import { StaticImage } from 'gatsby-plugin-image';




class OurAndroid extends React.Component {

  render() {

    return (
      <section className="android pt-100 mb-0 mobile-android">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            <h3 className="heading-h2 mb-15"><span className="heading-h3">Our <span className="bluecolor">Cryptocurrency Exchange Script</span> </span>for Android & iOS App Development</h3>
            <p className="pharagraph head">Our platform has the ideal user interface for traders.</p>
          </div>

          <div className="row table-content mobilewhitebox orderflex">
            <div className="col-md-12 col-lg-7 order2">
              <p className="pharagraph" >As we know, smartphone users are increasing rapidly in all parts of the world. So most crypto traders prefer cryptocurrency trading mobile applications for buying and selling cryptocurrency. Kickstarting your cryptocurrency business with a user-friendly cryptocurrency trading mobile app will help you attract global digital coin users easily. We at Coinsclone offer a top-notch cryptocurrency exchange mobile app development service with impeccable outcomes.
              </p>
              <p className="pharagraph mmb0" >By using our service, you can create and launch a stunning cryptocurrency trading mobile app with all elite trading features and security mechanisms. Our experienced developers will craft the mobile app with an impressive user interface as per your business requirements which will be compatible with both Android and IOS platforms.</p>
              <div className="mobile-hide">
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 and-img text-center">
                <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/cryptocurrency-exchange-mobile-app-development.png"
                        alt="Cryptocurrency Exchange Mobile App Development"
                        width={525} />
            </div>
          </div>
          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
          <div className="mobile-on">
          <ButtonComponent />
          </div>
        </div>
      </section>
    )
  }
}

export default OurAndroid