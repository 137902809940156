import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class TopFeatures extends React.Component {

  render() {

    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
              <h3 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Top Features</span> of our </span>Cryptocurrency Exchange  Script</h3>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left">
                <h4 className="heading-h4">Advanced Trading Engine</h4>
                <p className="pharagraph">Our first-class cryptocurrency trading script comes with a cutting-edge Trading Engine which matches the buy and sell orders quickly without any latency. </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home-new/advanced-trading-engine.webp"
                  alt="Advanced Trading Engine image1"
                  width={552}
                />
            </div>
          </div>
          <div className="row table-content">
            <div className="col-md-12 col-lg-6  order2">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home-new/multi-cryptocurrency-wallet.webp"
                  alt="Multi-Cryptocurrency Wallet Integration image1"
                  width={558}
                />
            </div>
            <div className="col-md-12 col-lg-6 order1" >
              <div className="top-right">
                <h4 className="heading-h4">Multi-Cryptocurrency Wallet Integration</h4>
                <p className="pharagraph">Cryptocurrency wallet is the most essential feature as it helps the users to store, receive, and transfer all major virtual coins securely. Our clone script has multi-cryptocurrency wallet support with both user wallet and admin wallet integration. Our multi-crypto wallet enables secure storage and smooth transactions for all prominent cryptocurrency coins like bitcoin, ethereum, dash, bitcoin cash, dogecoin, ripple, etc.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2" >
              <div className="top-left">
                <h4 className="heading-h4">High Transactions per Second
                </h4>
                <p className="pharagraph">Our superior cryptocurrency exchange script provides extraordinary performance by handling more than 1,00,000 transactions per second without any lag. Therefore, your users can get the finest trading experience.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home-new/high-transactions-per-second.webp"
                  alt="High Transactions per Second image1"
                  width={536}
                />
            </div>
          </div>
          <div className="row table-content">
            <div className="col-md-12 col-lg-6  order2">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home-new/security-ready.webp"
                  alt="Security Ready image1"
                  width={536}
                />
            </div>
            <div className="col-md-12 col-lg-6 order1" >
              <div className="top-right">
                <h4 className="heading-h4">Security Ready</h4>
                <p className="pharagraph">Security is a major concern while crafting software for cryptocurrency trading. Thus, we enable the latest high-end security mechanisms in our script like Two-factor authentication, data encryption, HTTPs authentication, etc. The IP address will be automatically restricted in the admin server and we provide Cloudflare service to safeguard the cryptocurrency trading platform from fraudulent activities.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row table-content mb-0 orderflex">
            <div className="col-md-12 col-lg-6 order2 mb-0" >
              <div className="top-left">
                <h4 className="heading-h4">High Liquidity</h4>
                <p className="pharagraph mb-0">Our cryptocurrency exchange script comes with an API connected to any popular exchange. A secure API connection with other popular exchanges helps in achieving liquidity</p>
                  
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/home-new/high-liquidity.webp"
                  alt="High Liquidity image1"
                  width={538}
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures