import React from 'react'
import Slider from "react-slick";


class Testimonial extends React.Component {


  render() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };


    return (
      <div className='pt-100'>
      <section className="testimonial mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">  
              <div className="testi-left">
                <p className="heading-h2"><span className="heading-h3 bluecolor">Our Customers</span> Love what we do</p>
                <p className="pharagraph" >Customer feedback and reviews are our major concerns! Here we have shared a few feedbacks of our reputed clients.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 bluebg" >
              <div className="testi-right text-center">
                <Slider {...settings}>
                  <div className="testi-card">
                      <img
                        rel="preload" fetchpriority="high" as="image"
                        data-sizes="(min-width: 800px) 142px"
                        data-src="https://coinsclone.mo.cloudinary.net/images/home/gig.png"
                        data-srcset="https://coinsclone.mo.cloudinary.net/images/home/gig.png 142px,
                          https://coinsclone.mo.cloudinary.net/images/home/gig.png 125w,
                          https://coinsclone.mo.cloudinary.net/images/home/gig.png 130w,"
                        className="lazyload"
                        alt="gig" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor testi">GIG EXCHANGE</p>
                    <p className="pharagraph">I personally recommend Coinsclone as an excellent choice for your blockchain development needs. Their assistance will ensure your launch is a success and accelerate your time to market.
                    </p>
                  </div>
                  <div className="testi-card">
                    <img
                      rel="preload" fetchpriority="high" as="image"
                      data-sizes="(min-width: 800px) 142px"
                      data-src="https://coinsclone.mo.cloudinary.net/images/home-new/xpress.png"
                      data-srcset="https://coinsclone.mo.cloudinary.net/images/home-new/xpress.png 142px,
                        https://coinsclone.mo.cloudinary.net/images/home-new/xpress.png 125w,
                        https://coinsclone.mo.cloudinary.net/images/home-new/xpress.png 130w,"
                      className="lazyload"
                      alt="xpress" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor testi">XPRESSCRYPTO</p>
                    <p className="pharagraph">Great company! The entire team had a lot of talent; they are very solid as team, and they don’t hesitate till the result is the best one possible.
                    </p>
                  </div>
                  <div className="testi-card">
                    <img
                      rel="preload" fetchpriority="high" as="image"
                      data-sizes="(min-width: 800px) 142px"
                      data-src="https://coinsclone.mo.cloudinary.net/images/home/tokash.png"
                      data-srcset="https://coinsclone.mo.cloudinary.net/images/home/tokash.png 142px,
                        https://coinsclone.mo.cloudinary.net/images/home/tokash.png 125w,
                        https://coinsclone.mo.cloudinary.net/images/home/tokash.png 130w,"
                      className="lazyload"
                      alt="tokash" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor testi">TOKASH</p>
                    <p className="pharagraph">Coinsclone listened to my needs, created and maintained my website in a timely reasonable manner. I would highly recommend them to anyone!
                    </p>
                  </div>
                  <div className="testi-card">
                    <img
                      rel="preload" fetchpriority="high" as="image"
                      data-sizes="(min-width: 800px) 142px"
                      data-src="https://coinsclone.mo.cloudinary.net/images/home/nat.png"
                      data-srcset="https://coinsclone.mo.cloudinary.net/images/home/nat.png 142px,
                        https://coinsclone.mo.cloudinary.net/images/home/nat.png 125w,
                        https://coinsclone.mo.cloudinary.net/images/home/nat.png 130w,"
                      className="lazyload"
                      alt="nat" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor testi">NAT5 EXCHANGE </p>
                    <p className="pharagraph">The work done by the Coinsclone team is very professional, fast and in terms of quality, it is excellent! I am very satisfied with the relationship that has developed.
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Testimonial