import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class Benefits extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading benefof bgremove pt-100 mb-0">
        <div className="container">
            <h4 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Benefits</span> of Using</span>
            Our Cryptocurrency Exchange Script</h4>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Cost-effective
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Reduced Time to Build
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Easy Customization
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  No Need of Technical Expertise
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  High ROI
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Cost-effective</p>
                      <p className="pharagraph">By using ready-made cryptocurrency exchange script you can save the development cost of the software which will be lower than developing from base.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center" >
                    <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/cost-effective.png" 
                      alt="Cost-effective image1"
                      width={320} />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Reduced Time to Build</p>
                      <p className="pharagraph">Our software is fully designed, developed, multi-tested, verified by our blockchain experts, and ready for deployment. Thus, it will save time to create and launch a cryptocurrency trading platform.</p>
                      
                    </div>
                    <div className="col-md-6 text-center" >
                    <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/redused-time-to-build.png" 
                      alt="Reduced time to build image1"
                      width={357} />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Easy Customization</p>
                      <p className="pharagraph">Our ultra-modern script is 100% customizable. So you can alter all the basic trading features, logo, themes, designs, and also other functionalities in a trouble-free manner.</p>
                      
                    </div>
                    <div className="col-md-6 text-center" >
                    <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/easy-customization.png" 
                      alt="Easy customization image1"
                      width={347} />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">No Need Of Technical Expertise</p>
                      <p className="pharagraph">As you are getting a product from coinsclone, you don't need to hire a team of developers separately. We have a team of developers who can help you to create and launch a crypto platform within a few days with low effort and less technical resources.</p>
                      
                    </div>
                    <div className="col-md-6 text-center" >
                    <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/no-need-of-technical-expertise.png" 
                      alt="Technical Expertise image1"
                      width={417} />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">High ROI</p>
                      <p className="pharagraph">Our Tailor-made software will help you to develop and launch a superfine cryptocurrency trading platform with a stunning UI/UX that can guarantee a high return on investment.
                      </p>
                      
                    </div>
                    <div className="col-md-6 text-center" >
                    <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/high-roi.png" 
                      alt="High ROI image1"
                      width={320} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Benefits