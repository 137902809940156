import { StaticImage } from 'gatsby-plugin-image'
import React,{useEffect,useState} from 'react'

const TechStack = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100'>
    <section className="stack mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center">
            <h4 className="heading-h2 mb-15"><span className="heading-h3"><span className="bluecolor">Technology Stack </span> Used For </span>
              Our Cryptocurrency Trading Script
            </h4>
            <p className="pharagraph head">Our cryptocurrency exchange script is completely crafted with the latest technology tools which enable your users to get the best user-friendly experience. The cutting-edge technology stacks that we use in our script are Flutter, Kotlin, node JS, swift, and more.
            </p>
            <div className="quick text-center">
              { isDesktop && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home/technologies-stack.webp" 
              alt="Advanced Technology Stack in Our Cryptocurrency Exchange Script image1"
              width={1100} />
              )}
              { isMobile && (
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/techstack.webp" 
              alt="Advanced Technology Stack in Our Cryptocurrency Exchange Script image1"
              width={311} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default TechStack