import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100 mobile-how-our'>
    <section className="how-our mb-0">
      <div className="container">
          <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">How Does</span> Our Cryptocurrency Exchange Script work?</h2>
          { isDesktop && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/workflow-coinsclone.webp" 
              alt="coinsclone-workflow"
              width={1100} />
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/cryptocurrency-exchange-script-mobile.webp" 
              alt="coinsclone-workflow"
              width={392} />
              )}
      </div>  
    </section>
    </div>
  )
}

export default HowOur